import { css } from 'lit';

export default css`
  .u-empty-button {
    background: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    border: none;
  }
`;
