import './elements/myrh-certifications/myrh-certifications';
import './elements/myrh-eco-solutions/myrh-eco-solutions';
import './elements/myrh-partner-connect/myrh-partner-connect';
import './elements/myrh-support-cases/myrh-support-cases';
import './elements/myrh-training/myrh-training';
import './elements/myrh-trials/myrh-trials';
import './elements/myrh-trending/myrh-trending';
import './elements/myrh-product-lifecycle/myrh-product-lifecycle';
import './elements/myrh-subscriptions/myrh-subscriptions';
import * as DevtoolsConfig from './elements/myrh-devtools/config.js';

DevtoolsConfig.init(new URL('./elements/myrh-devtools/myrh-devtools.min.js', import.meta.url));
