import { css } from 'lit';
import utilities from './utilities.js';

export default css`
  ${utilities}

  button:has(> rh-tag) {
    background: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    border: none;
  }

  button > rh-tag {
    text-decoration: underline;
  }

  button:where(:hover, :focus) rh-tag {
    --rh-border-width-sm: var(--rh-border-width-md, 2px);
  }
`;
