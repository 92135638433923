import type { Client } from '@rhitm-incubator/oidc-manager';

export type Preference = {
  key: string;
  value: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  expiresAt: string;
  userSSO: string;
}

export class PreferencesService {
  constructor(public rhapiEndpoint: string, public user: NonNullable<Client['user']>) { }

  /**
   * Fetches a user's preferences
   *
   * @param key Preference['key']
   * @returns Promise<Preference[]>
   */
  getPreferences(): Promise<Preference[]> {
    const preferredUsername = this.user.profile.preferred_username;
    const accessToken = this.user.access_token;
    const url = new URL(this.rhapiEndpoint);
    url.pathname = `hydra/rest/users/sso/${preferredUsername}/preferences`;

    return fetch(url.toString(), {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${accessToken}`
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(`getPreferences service returned status not ok: status ${res.status}`);
        }
        if (res.status !== 200) {
          return [];
        }
        return res.json();
      });
  }

  /**
   * Fetches a user's preference by key
   *
   * @param key Preference['key']
   * @returns
   * - Promise<Preferce> if there was a preference found
   * - Promise<null> if there was no preference found
   */
  getPreference(key: Preference['key']): Promise<Preference | null> {
    const preferredUsername = this.user.profile.preferred_username;
    const accessToken = this.user.access_token;
    const url = new URL(this.rhapiEndpoint);
    url.pathname = `hydra/rest/users/sso/${preferredUsername}/preferences/${key}`;

    return fetch(url.toString(), {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${accessToken}`
      }
    })
      .then(res => {
        // 404 indicates that the key does not exist on
        // this user's preference list
        if (res.status === 404) {
          return null;
        }
        return res.json();
      });
  }

  /**
   * Add a user's preference by key
   *
   * @param key Preference
   * @returns Promise<Preferce>
   */
  addPreference(preference: Pick<Preference, 'key' | 'value'> & Partial<Preference>): Promise<Preference> {
    const preferredUsername = this.user.profile.preferred_username;
    const accessToken = this.user.access_token;
    const url = new URL(this.rhapiEndpoint);
    url.pathname = `hydra/rest/users/sso/${preferredUsername}/preferences`;

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(preference),
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(`addPreference service returned status not ok: status ${res.status}`);
        }
        return res.json();
      }).then((res: Preference[]) => {
        const updatedPreference = res.find(i => i.key === preference.key);
        if (!updatedPreference) {
          throw new Error(`addPreference service did not receive a preference from the API.`);
        }
        return updatedPreference;
      });
  }

  /**
   * Updates a user's preference by key
   *
   * @param key Preference
   * @returns Promise<Preferce>
   */
  updatePreference(preference: Pick<Preference, 'key' | 'value'> & Partial<Preference>): Promise<Preference> {
    const preferredUsername = this.user.profile.preferred_username;
    const accessToken = this.user.access_token;
    const url = new URL(this.rhapiEndpoint);
    url.pathname = `/hydra/rest/users/sso/${preferredUsername}/preferences`;

    return fetch(url.toString(), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(preference),
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(`updatePreference service returned status not ok: status ${res.status}`);
        }
        return res.json();
      }).then((res: Preference[]) => {
        const updatedPreference = res.find(i => i.key === preference.key);
        if (!updatedPreference) {
          throw new Error(`updatePreference service did not receive a preference from the API.`);
        }
        return updatedPreference;
      });
  }

  /**
   * Adds or updates a user's preference by key
   *
   * @param key Preference
   * @returns Promise<Preferce>
   */
  addOrUpdatePreference(preference: Pick<Preference, 'key' | 'value'> & Partial<Preference>): Promise<Preference> {
    // if value is null then we know we need to create
    // the record
    if (preference.value === null) {
      return this.addPreference(preference);
    }
    return this.updatePreference(preference);
  }
}

